import React from 'react'
import Styled from 'styled-components';
import Layout from '../components/layout'
import L from '../icons/logo.svg'

const Container = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Marquee = Styled.h2`
  max-width: 800px;
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-weight: 400;
  font-size: ${16/16}rem;
  letter-spacing: 1px;
  line-height: 1.666;
  text-align: center;
  padding: 0 25px;
  margin-top: 260px;
  @media screen and (min-width: 768px) {
    padding: 0 0 25px;
    padding-top: 40px;
    margin-top: 0;
    font-size: ${20/16}rem;

  }
  @media screen and (min-width: 992px) {
    margin-top: 0;
    font-size: ${27/16}rem;
    line-height: 57px;
    text-align: justify;
    margin-left: 70px;
  }
`
export const NCAL = Styled(L)`
  width: 120px;
  height: 190px;
  display: block;
  margin-top: 60px;
  position: absolute;
  left: 50%;
  margin-left: -60px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`
const IndexPage = () => (
  <Layout>
    <Container>
      <Marquee>Noble Caplan Abrams is a full service Talent Agency distinguished in Canada for over 25 years, representing many of the entertainment industry’s leading and emerging talent.</Marquee>
    </Container>
  </Layout>
)

export default IndexPage
